<script lang="ts" setup>
const intervalId = ref();
const imagesDictionary = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
const imageQueue = ref(imagesDictionary.slice(0, 5));
const counter = ref(2873);
const arraybleCounter = computed(() => numberFormat(counter.value).split(""));

const addImageToQueue = () => {
	const randomIndex = Math.floor(Math.random() * imagesDictionary.length);
	const newImage = imagesDictionary[randomIndex];

	if (!imageQueue.value.includes(newImage)) {
		counter.value += 1;
		imageQueue.value.unshift(newImage);
		imageQueue.value.pop();
	}
};

const addImageWithInterval = () => {
	intervalId.value = setInterval(addImageToQueue, 2000);
};

onMounted(() => {
	addImageWithInterval();
});

onUnmounted(() => clearInterval(intervalId.value));
</script>

<template>
	<div class="list">
		<TransitionGroup name="list-complete">
			<NuxtImg
				v-for="img in imageQueue"
				:key="img"
				class="card-wrapper list-complete-item"
				:src="`/nuxt-img/banners/rate/${img}.png`"
				format="avif"
				width="34"
				height="34"
				alt="user"
			/>
		</TransitionGroup>
		<div class="counter">
			<TransitionGroup name="counter">
				<AText
					v-for="(item, index) in arraybleCounter"
					:key="item + index"
					variant="topeka"
					:modifiers="['semibold']"
					class="amount-item"
				>
					{{ item }}
				</AText>
			</TransitionGroup>
		</div>

		<div class="rate">
			<ASvg name="20/star" class="icon" />
			<AText variant="toledo">4.8</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
img {
	margin: 0 0 0 -8px;
	object-fit: cover;
}

.list {
	margin: 12px 0 0 8px;
	display: flex;
	align-items: center;
}

.rate {
	display: flex;
	align-items: center;
	gap: 4px;
}

.list {
	&-complete-item {
		transition: all 1s;
		display: inline-block;
	}

	&-complete-leave-to {
		opacity: 0;
		transition: all 0.5s !important;
		transform: translateX(100px) scale(0);
	}

	&-complete-leave-active {
		position: absolute;
		transition: 1s;
	}
}

.counter {
	width: 65px;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 999px;
	border: 2px solid var(--neutral-800);
	background: var(--neutral-950);
	margin-left: -8px;
	position: relative;
	z-index: 2;
	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(-10px);
	}

	&-leave-active {
		position: absolute;
	}

	&-leave-to {
		transform: translateY(2px);
		transition: all 0s !important;
	}
}

.amount-item {
	transition: all 1s;
	display: inline-block;
}
</style>
